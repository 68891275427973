
function ComingSoon() {
    return (
        <div className="fullscreen flex-center">
            <div>
            <h1 className="font-heading text-highlight"><i class="fa-regular fa-face-meh-blank"></i> Whoopsie.</h1>
            <p>I am tinkering on this site, check back later!</p>
            </div>
        </div>
    )
}

export default ComingSoon;