import React from 'react';

import imgMenu from 'images/icons/menu.png';
import imgLinkedIn from 'images/icons/linkedin.png';
import imgGithub from 'images/icons/github.png';
import imgProjects from 'images/icons/project-management.png';
import Skill from 'components/Skill/Skill';
import SideNavigation from 'components/SideNavigation/SideNavigation';
import Footer from 'components/Footer/Footer';

function App() {
	return (
		<div className="app">
			<aside className="sidebar">
				<SideNavigation/>
			</aside>
			
			<div className="main">

				<div className="section fullscreen flex-center base-padding">
					<div id="intro" className="ss1">
						<h2 className="text-xxl mb5 font-heading">
							<span>Hello.</span>
							<br/>
							<span>My name is <span className="text-highlight">Gurparsad Bajwa.</span></span>
						</h2>

						<p className="mb5">
							I&apos;m a Full Stack Developer based in Toronto, Canada, helping companies deliver beautiful, intuitive and secure online experiences through
							solid research, design and development of web applications.
						</p>

						<a href="#" class="button">A little about me</a>
					</div>
				</div>

				<div id="expertise" className="section fullscreen flex-center base-padding">
					<div className="ss2">
						<h2 className="text-xxl mb5 font-heading">
							Expertise
						</h2>

						<div className="flex-column flex-wrap flex-g1">
							<div className="flex flex-wrap flex-g1">
								<Skill skill="HTML"/>
								<Skill skill="CSS"/>
								<Skill skill="JavaScript"/>
								<Skill skill="TypeScript"/>
							</div>
							<div className="flex flex-wrap flex-g1">
								<Skill skill="React"/>
								<Skill skill="Angular"/>
								<Skill skill="C# .NET MVC"/>
								<Skill skill="Java Spring Boot"/>
							</div>
							<div className="flex flex-wrap flex-g1">
								<Skill skill="C++"/>
								<Skill skill="C#"/>
								<Skill skill="Java"/>
							</div>
							<div className="flex flex-wrap flex-g1">
								<Skill skill="MySQL"/>
								<Skill skill="Oracle"/>
								<Skill skill="MongoDB"/>
							</div>
						</div>

						<div className="flex flex-wrap flex-g2 mt5">
							<div className="legend-marker"><span className="marker"></span><span className="legend">Web Fundamentals</span></div>
							<div className="legend-marker"><span className="marker"></span><span className="legend">Programming Languages</span></div>
							<div className="legend-marker"><span className="marker"></span><span className="legend">Frameworks/Libraries</span></div>
							<div className="legend-marker"><span className="marker"></span><span className="legend">Databases</span></div>
						</div>
					</div>
				</div>

				<div id="contact" className="section fullscreen base-padding flex-center">
					<div className="inner">
						<h2 className="text-xxl mb5 font-heading">Contact</h2>
						<p className="mb5">Got an idea? I&apos;ll help you translate it into a product. Connect with me for starting a project or general inquiries. I&apos;ll buy the coffee!</p>

						<div className="two-col-form">
							<div className="fieldset">
								<label for="contactName">Name</label>
								<input/>
							</div>
							<div className="fieldset">
								<label for="contactEmail">Email</label>
								<input/>
							</div>
							<div className="fieldset">
								<label for="contactMsg">Message</label>
								<textarea/>
							</div>
						</div>

						<button className="button">Submit</button>
					</div>
				</div>

				<Footer/>
			</div>
		</div>
	);
}

export default App;
